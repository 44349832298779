import React from 'react';
import { useLocation } from 'react-router-dom';
const Front = React.lazy(() => import('./front'));
const Boffice = React.lazy(() => import('./boffice'));


function AppRouter() {
  const pathName = useLocation().pathname;
  if (!pathName.includes("/boffice")) {
    return (
      <Front />
    );
  } else {
    return (
      <Boffice />
    );
  }
}

export default AppRouter;
