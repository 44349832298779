/* eslint-disable */
import React, { useState, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

interface GnbItem {
  title: string;
  link: string;
  depth?: string;
  cssActive : string;
}

const BofficeHeader = () => {
  const pathName = useLocation().pathname;
  const [cookies, setCookie, removeCookie] = useCookies(["rememberAdminId"]); 
  const [isActive, setIsActive] = useState(false);
  const [isGnbActive, setIsGnbActive] = useState(false);
  const [gnbIdx, setGnbIdx] = useState(0);

  // 상단 Gnb
  const gnbData: GnbItem[] = [
    { title: "회원관리", link: "/boffice/member/list", cssActive:"/boffice/member" },
  ];

  const subMember: GnbItem[] = [
    { title: "회원관리", link: "/boffice/member/list", depth: "depth1", cssActive:"/boffice/member/list" },
    { title: "회원목록", link: "/boffice/member/list", depth: "depth2", cssActive:"/boffice/member/list"},
  ];

  const renderGnb = useCallback(() => {
    return gnbData.map((v, idx) => {
      return (
        <NavLink
          to={v.link}
          key={idx}
          onClick={() => {
            setIsGnbActive(false);
            setGnbIdx(idx + 1);
          }}
          className={`link ${
            isGnbActive || gnbIdx === idx + 1 || pathName.includes(v.cssActive)  ? "active" : ""
          }`}
        >
          {v.title}
        </NavLink>
      );
    });
  }, [gnbIdx, isGnbActive]);

  const renderSub = useCallback(() => {
    let arr: GnbItem[] = [];
    if (pathName.startsWith("/boffice/login")) {
      return <></>;
    } else if (pathName.startsWith("/boffice/member")) {
      arr = subMember;
    } else {
      // arr = 'subMember';
    }

    return (
      <div className="gnb_sub_wrap">
        <NavLink to="/boffice/member/list" className="logo">
          <h1>동산병원</h1>
        </NavLink>
        <div className="list">
          {arr.map((v, idx) => {
            return (
              <NavLink
                to={v.link}
                key={idx}
                onClick={() => setIsActive(true)}
                className={`link ${v.depth} ${isActive || pathName.includes(v.cssActive) ? "lnb_active" : ""}`}
              >
                {v.title}
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  }, [pathName, subMember, isActive]);

  return (
    <header
      className={`adm_head ${
        pathName.includes("/login") ? `isHideGnb` : ``
      }`}
    >
      <section className="gnb_wrap">
        <div className="adm_com_center_wrap">
          <section className="gnb_list">{renderGnb()}</section>
          <section className="menu_list">
            <NavLink to="/boffice/login" className="link" onClick={() => { {setGnbIdx(0); removeCookie("rememberAdminId"); localStorage.removeItem("adminlogin")}}}>
              로그아웃
            </NavLink>
            <NavLink to="/boffice/login" className="link" onClick={() => { setGnbIdx(0); }}>
              로그인
            </NavLink>
          </section>
        </div>
      </section>
      {renderSub()}
    </header>
  );
}

export default BofficeHeader;
