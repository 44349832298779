import React from 'react';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Suspense } from "react";
import Routers from './contents/router/AppRouter';
import BofficeHeader from "./contents/views/boffice/page/BofficeHeader";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const pathName = useLocation().pathname;

  return (
    <Suspense fallback={<div>Loading... </div>}>
      {pathName.includes("/boffice") ? <BofficeHeader /> : null}
      <Routers />
    </Suspense>
  );
}

export default App;
